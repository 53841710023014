import React from 'react';
import strings from '../res/strings';
import Link from './Globals/Link';

const Home = ({ currentLang }) =>{

    const stringsRoot = currentLang === 'en' ? strings.en : strings.fr

    return(
        <section id="home" className="section">
            <div className="container-wrap">                        
                <h1 className="entry-title" data-jarallax-element="0 30">
                    { stringsRoot.homeSection.hi }
                    <br/>
                    { stringsRoot.homeSection.iAm } <span>Damien</span>
                </h1>
                <img className="main-photo block-right" data-jarallax-element="0 -30" src="images/photo-1.jpg" alt=""/>                        
                <Link href='#myjob' className='main-btn slow-scroll'>
                    <img src="images/scroll-icon.svg" alt="scroll"/>
                </Link>
            </div>
        </section>
    )
}

export default Home