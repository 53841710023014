import strings from "../../res/strings"
import Link from "../Globals/Link"
import ListItem from "../Globals/ListItem"
import UnorderedList from "../Globals/UnorderedList"


const HeaderNavigation = ({children, currentLang}) =>{

    const stringsRoot = currentLang === 'en' ? strings.en : strings.fr

    return(
        <div className="s-nav">
            <div className="nav-count">
                <div className="current-num">
                    <span>01</span>
                </div>
                <div className="pagination-sep">/</div>
                <div className="total-pages-num">03</div>
            </div>                                                            
            <div className="nav-container">
                <Link className='site-title slow-scroll' href='#home' text='DAMIEN PASULJ' />
                <nav className="nav-menu">
                    <UnorderedList className='nav-list'>
                        <ListItem>
                            <Link href='#home' text={stringsRoot.hi} />
                        </ListItem>
                        <ListItem>
                            <Link href='#myjob' text={stringsRoot.myJob} />
                        </ListItem>
                        <ListItem>
                            <Link href='#about' text={stringsRoot.about} />
                        </ListItem>
                    </UnorderedList>
                </nav>
            </div>
            {children}
        </div>
    )
}

export default HeaderNavigation