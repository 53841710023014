import React, { Component } from "react";
import About from "./components/About";
import Link from "./components/Globals/Link";
import ListItem from "./components/Globals/ListItem";
import UnorderedList from "./components/Globals/UnorderedList";
import Header from "./components/Header";
import Home from "./components/Home";
import Job from "./components/Job";
import HeaderNavigation from "./components/Navigation/HeaderNavigation";

const lang = "en"

class PageWrapper extends Component{

  // Gestion du state
  state = {
    lang : lang
  }

  // Gestion des events
  handleClick = (targetLang) => {
    let lang = {...this.state.lang }
    lang = targetLang

    this.setState({ lang })
  }
  render(){

    // Init variables
    const currentLang = this.state.lang

    // Rendu du composant
    return(
      <div className="page-wrapper">
        <Header>
          <HeaderNavigation currentLang={currentLang}>
            <UnorderedList className='nav-soc'>
                <ListItem>
                    <Link href='#' text='FR .' className={ currentLang === 'fr' ? 'active' : '' } clickMethod={() => this.handleClick("fr")} />
                </ListItem>
                <ListItem>
                    <Link href='#' text='EN .' className={ currentLang === 'en' ? 'active' : '' } clickMethod={() => this.handleClick("en")} />
                </ListItem>
            </UnorderedList>
          </HeaderNavigation>
        </Header>
        <Home currentLang={currentLang} />
        <Job currentLang={currentLang} />
        <About currentLang={currentLang}/>
      </div>
    )
  }
}

export default PageWrapper;
