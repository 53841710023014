import React from 'react';
import strings from '../res/strings';
import Link from './Globals/Link';
import ListItem from './Globals/ListItem';

const About = ({ currentLang }) =>{

    const stringsRoot = currentLang === 'en' ? strings.en : strings.fr

    return(
        <section id="about" className="section">
            <h2 className="title">{ stringsRoot.about.toUpperCase() }</h2>
            <div className="section-des">
                { stringsRoot.aboutSection.subtitle }
            </div>

            <div className="content-670">
                <p>
                    { stringsRoot.aboutSection.firstParagraph }
                </p>
            </div>

            <img className="about-img block-right" data-jarallax-element="0 -40" src="images/about-img.jpg" alt=""/>

            <ul className="about-info" data-threshold="0 0" data-jarallax-element="40 0">
                <ListItem>
                    <h6>{ stringsRoot.eMail.toUpperCase() }</h6>
                    <Link href='mailto:contact@damienpasulj.com'>
                    <span className="__cf_email__">dagobert1992@gmail.com</span>
                    </Link>
                </ListItem>

                <ListItem>
                    <h6>{ stringsRoot.aboutSection.role.toUpperCase() }</h6>
                    <span>{ stringsRoot.aboutSection.frontEndEngineer } /<br/>{ stringsRoot.aboutSection.conceptor }</span>
                </ListItem>
            </ul>

            <div className="about-text content-670">
                <p>
                    { stringsRoot.aboutSection.secondParagraph }
                </p>
                <p>
                { stringsRoot.aboutSection.thirdParagraph }
                </p>
                <p>
                { stringsRoot.aboutSection.fourthParagraph }
                </p>
            </div>

            <img className="signature" src="images/signature.png" alt=""/>

        </section>
    )
}

export default About