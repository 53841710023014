const strings = {
    fr: {
        hi: 'Tapes m\'en 5 !',
        myJob: 'Mon métier',
        about: 'À propos',
        eMail: 'e-mail',
        homeSection:{
            hi: 'Salut,',
            iAm: 'moi c\'est'
        },
        myJobSection:{
            interfaceDesignAndConception : 'De la conception d\'interface au développement.',
            firstParagraph : 'En tant que développeur, mon métier consiste à concevoir et donner vie à des interfaces élégantes et pratiques d’utilisation pour les utilisateurs. Mon domaine de prédilection est le web, mais je suis également familier avec le développement d’applications mobiles et la création de visuels à destination de tous types de supports.',
            secondParagraph : 'Pour mener à bien la mission que vous me confiez, je me tiens informé des pratiques les plus répandues et les plus adaptées au projet avec toujours pour principal objectif le meilleur compromis rapidité/durabilité possible.',
            linKedIn: 'Tissons des liens !',
            morePrecisions: 'Plus de précision sur mes compétences ?',
            references: 'Références',
            conception: 'Conception',
            conceptionText: 'L’identité visuelle ainsi que l’ergonomie sont les ingrédients les plus important d’un site ou d’une application qui fonctionne mais, forcément… tout ça n’est appréciable que lorsque c’est élégant.',
            development: 'Développement',
            developmentText: 'Le nerf de la guerre… il faut rester léger et rapide, être alerte sur les nouvelles technologies afin de ne pas accumuler de la dette technique. D’ailleurs… savais-tu que ce site était développé en React ?'
        },
        aboutSection:{
            role: 'Rôle',
            frontEndEngineer: 'Développeur',
            conceptor: 'Concepteur d\'identité visuelle, vidéos, et marketing profesionnel',
            subtitle: 'Les plus important, c\'est l\'équilibre.',
            firstParagraph: 'Passionné d\'interface et des technologies informatique depuis tout jeune, je suis également esthète dans l’âme. J’adore concevoir des projets, d’abord inertes, et leur donner vie sur internet.',
            secondParagraph: 'Cela a commencé vers 2012 lors de ma formation de graphiste à l’Idem. Nous étudiions la surface des sites internet, leur ergonomie, leur apparence, leur identité. Qu’est-ce qui fait qu’un site fonctionne, d’un point de vue marketing et un autre moins ?',
            thirdParagraph: 'De fil en aiguille, j’ai voulu en savoir un peu plus sur le dessous de l’iceberg. Comment passait-on d’une simple maquette à un site en ligne, et par quel procédé ? J’étais très curieux.',
            fourthParagraph: 'Cette curiosité m’a poussé a développer les compétences dont je dispose aujourd’hui, me permettant ainsi de maîtriser toutes les étapes de la chaîne de création d’une identité visuelle.'
        }
    },
    en: {
        hi: 'Say hi!',
        myJob: 'My job',
        about: 'About me',
        eMail: 'email',
        homeSection:{
            hi: 'Hi,',
            iAm: 'I\'m'
        },
        myJobSection:{
            interfaceDesignAndConception : 'From interface design to development.',
            firstParagraph : 'As a developer, my job is to design and bring to life elegant and user-friendly interfaces for visitors (you). My area of expertise is the web, but I am also familiar with mobile application development and the creation of visuals for all types of media.',
            secondParagraph : 'In order to carry out the mission that you entrust to me, I keep myself informed of the most widespread practices that are best suited to the project, always with the main objective of achieving the best possible compromise between speed and durability.',
            linKedIn: 'Get linked in !',
            morePrecisions: 'More information about my skills?',
            references: 'Portfolio',
            conception: 'Conception',
            conceptionText: 'Visual identity and ergonomy are the most important ingredients of a successful website or application but, of course... all this can only be appreciated when it\'s done with elegance.',
            development: 'Development',
            developmentText: 'The sinews of war... you have to stay light and fast, be alert to new technologies so as not to accumulate technical debt. By the way... did you know that this site was developed in React?'
        },
        aboutSection:{
            role: 'Role',
            frontEndEngineer: 'Front-End Engineer',
            conceptor: 'Profesional marketing, video and visual identity conception',
            subtitle: 'The most important thing is balance.',
            firstParagraph: 'Passionate about user-interfaces and technologies since I was young, I am also an aesthete at heart. I love to design things, initially inert, and bring them to life on the internet, where everybody can see it. ',
            secondParagraph: 'It started around 2012 when I was a graphic designer in formation at Idem Art School. We were studying the surface of websites, their ergonomics, their appearance, their identity. What makes a site work, from a marketing point of view, and another less so?',
            thirdParagraph: 'One thing led to another and I wanted to know a little more about the underside of the iceberg. How do you go from a simple model to an online site, what was the process? I was very curious.',
            fourthParagraph: 'This curiosity pushed me to develop the skills I have today, allowing me to master all the steps of the creation chain of a visual identity.'
        }
    }
}
  
  export default strings