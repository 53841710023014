import React from 'react';
import strings from '../res/strings';
import Link from './Globals/Link';
import ListItem from './Globals/ListItem';

const Job = ({ currentLang }) =>{

    const stringsRoot = currentLang === 'en' ? strings.en : strings.fr

    return(
        <section id="myjob" className="section">
            <div className="content-670">
                <h2 className="title">{ stringsRoot.myJob.toUpperCase() }</h2>
                <div className="section-des">
                    { stringsRoot.myJobSection.interfaceDesignAndConception }
                </div>
                <p>
                { stringsRoot.myJobSection.firstParagraph }
                </p>
                <p>
                    { stringsRoot.myJobSection.secondParagraph }
                </p>
            </div>

            <ul className="serv-link-cover block-right" data-jarallax-element="0 40">
                {/* <ListItem>
                    <Link href='#' text={ stringsRoot.myJobSection.references.toUpperCase() } />
                </ListItem> */}
                <span className='m-right-10'>{ stringsRoot.myJobSection.morePrecisions }</span>
                <ListItem>
                    <Link href='https://fr.linkedin.com/in/damien-pasulj-700928ba' target='_blank' text={ stringsRoot.myJobSection.linKedIn.toUpperCase() }/>
                </ListItem>
            </ul>

            <div className="content-670">
                <div className="serv-block-wrap">
                    <div className="serv-block-item">
                        <img src="images/serv-icon-1.svg" alt=""/>
                        <h4>{ stringsRoot.myJobSection.conception.toUpperCase() }</h4>
                        <p>
                            { stringsRoot.myJobSection.conceptionText }
                        </p>
                    </div>
                    <div className="serv-block-item">
                        <img src="images/serv-icon-3.svg" alt=""/>
                        <h4>{ stringsRoot.myJobSection.development.toUpperCase() }</h4>
                        <p>
                        { stringsRoot.myJobSection.developmentText }
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Job