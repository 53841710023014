import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';

const Header = ({children}) =>{
    return(
        <Fragment>
            <div id="nav-btn" className='nav-btn'>
                <span className="nav-btn-cover">
                    <span className="menu-line"></span>                   
                </span>
            </div>
            {children}
        </Fragment>
    )
}

export default Header